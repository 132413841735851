<template>
  <v-container style="height:50vh;">
    <v-row class="align-center" align="center">
        <v-col cols="6" class="mb-4" align="left">
            <h1 class="display-2 text--secondary font-weight-bold mb-3">
            ✔ Discover Videos
            </h1>
            <h1 class="display-2 text--secondary font-weight-bold mb-3">
            ✔ Create Reactions
            </h1>
            <h1 class="display-2 text--secondary font-weight-bold mb-3">
            ✔ Earn Rewards
            </h1>

            <p class="subheading font-weight-regular mx-2 ma-6">
            Re-Up is an interactive social network that lets you discover, engage and promote music videos, movie clips,
            sport highlights, news stories and other awesome videos you enjoy.
            </p>

            <v-btn
            size="large"
            prepend-icon="mdi-apple"
            class="ma-1"
            variant="outlined"
            >GET THE APP</v-btn>
        </v-col>
        <v-col cols="6">
            <v-img
            :src="require('../../assets/iPhoneX_hand_masked.webp')"
            class="my-3"
            height="400"
            />
        </v-col>
    </v-row>

    <v-divider></v-divider>
  </v-container>
</template>

<script>

export default {
  name: 'LandingHero',

  data: () => ({
  })
}
</script>
