<template>
<v-container style="height:100vh;">
    <v-row class="align-center" align="center">
        <v-col cols="12" class="mb-4" align="center">
            <h1 class="display-1 text--secondary font-weight-bold mb-4">
            Why ReUp?
            </h1>
        </v-col>
        <v-col cols="12" class="mb-4" align="center">
            <p class="display-1 text--secondary mb-4">
            Brough to you by a passionate team of creators, musicians, artists, geeks and video engineers on a mission to connect you with content and a community that creates curiosity drives conversation & inspires creativity.
            </p>
        </v-col>
    </v-row>

    <v-row class="align-center" align="center">
        <v-col cols="4" class="mb-6" align="center">
            <v-row align="center">
                <v-col cols="12" class="mb-6" align="center">
                    <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
                </v-col>
                <v-col cols="12" class="mb-6" align="center">
                    Discover relevant information that drives you to action!
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12" class="mb-6" align="center">
                    <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
                </v-col>
                <v-col cols="12" class="mb-6" align="center">
                    Exposure is never limited to your followers
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="4" class="mb-6" align="center">
            <v-img
            :src="require('../../assets/iPhoneX_hand_masked.webp')"
            class="my-3"
            height="400"
            />
        </v-col>
        <v-col cols="4" class="mb-6" align="center">
            <v-row align="center">
                <v-col cols="12" class="mb-6" align="center">
                    <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
                </v-col>
                <v-col cols="12" class="mb-6" align="center">
                    Community driven by shared interets helps to connect in more meaningful ways
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12" class="mb-6" align="center">
                    <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
                </v-col>
                <v-col cols="12" class="mb-6" align="center">
                    Expression is a cornerstone because we believe everyone deserves to be heard!
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    <v-row class="align-center" align="center">
        <v-col cols="4" class="mb-6" align="center">
        </v-col>
        <v-col cols="4" class="mb-6" align="center">
            <v-row align="center">
            <v-col cols="12" class="mb-6" align="center">
                <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
            </v-col>
            <v-col cols="12" class="mb-6" align="center">
                Engagement is our core focus, not just views
            </v-col>
            </v-row>
        </v-col>
        <v-col cols="4" class="mb-6" align="center">
        </v-col>
    </v-row>
</v-container>
</template>

<script>

export default {
  name: 'LandingWhyReup',

  data: () => ({
  })
}
</script>
