<template>
  <NavigationBar />
  <LandingHero />
  <LandingAbout />
  <LandingFeed />
  <LandingWhyReup />
  <FooterBar />
</template>

<script>
import { defineComponent } from 'vue'

// Components
import NavigationBar from '../components/NavigationBar.vue'
import LandingHero from '../components/landing/LandingHero.vue'
import LandingAbout from '../components/landing/LandingAbout.vue'
import LandingFeed from '../components/landing/LandingFeed.vue'
import LandingWhyReup from '../components/landing/LandingWhyReup.vue'
import FooterBar from '../components/FooterBar.vue'

export default defineComponent({
  name: 'HomeView',

  components: {
    NavigationBar,
    LandingHero,
    LandingAbout,
    LandingFeed,
    LandingWhyReup,
    FooterBar
  }
})
</script>
