<template>
  <v-container style="height:50vh;">
    <v-row class="align-center" align="center">
        <v-col cols="12" class="mb-4" align="center">
            <h1 class="display-1 text--secondary font-weight-bold mb-3">
              Join the Revolution
            </h1>
        </v-col>
        <v-col cols="3" class="mb-6" align="center">
            <v-row align="center">
              <v-col cols="12" class="mb-6" align="center">
                <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
              </v-col>
              <v-col cols="12" class="mb-6" align="center">
                Select items that interest you and fill up the ones you love the most!
              </v-col>
            </v-row>
        </v-col>
        <v-col cols="3" class="mb-6" align="center">
          <v-row align="center">
              <v-col cols="12" class="mb-6" align="center">
                <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
              </v-col>
              <v-col cols="12" class="mb-6" align="center">
                Discover Awesome Content from trusted partners that matches your interest
              </v-col>
            </v-row>
        </v-col>
        <v-col cols="3" class="mb-6" align="center">
          <v-row align="center">
              <v-col cols="12" class="mb-6" align="center">
                <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
              </v-col>
              <v-col cols="12" class="mb-6" align="center">
                Post a re-up (reaction, remix or review) using our innovative video cREator!
              </v-col>
            </v-row>
        </v-col>
        <v-col cols="3" class="mb-6" align="center">
          <v-row align="center">
              <v-col cols="12" class="mb-6" align="center">
                <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
              </v-col>
              <v-col cols="12" class="mb-6" align="center">
                Earn points, Level Up and Get Paid from your in-app engagement
              </v-col>
            </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'LandingAbout',

  data: () => ({
  })
}
</script>
