<template>
  <span id='navbar' style="height:20vh;">
    <v-card
    elevation="1"
    rounded="0"
    flat>

    <v-container class="">
      <v-row align-content="center">
        <v-col
          class="align-center"
          cols="12"
          sm="3"
        >
          <v-sheet class="ma-2 pa-2">
            <v-app-bar-nav-icon
              @click="drawer = true"
              class="d-flex d-sm-none"
            ></v-app-bar-nav-icon>

            <v-toolbar-title to="/">
              <router-link to="/">
                <v-img contain max-width='30%'
                src='../assets/logo.svg'
                ></v-img>
              </router-link>
            </v-toolbar-title>
          </v-sheet>
        </v-col>

        <v-col
          class="align-center"
          cols="12"
          sm="6"
        >
          <v-sheet class="ma-2 pa-2">
            <!-- Menu -->
            <v-btn
              v-for="item in menu_options"
              :key="item.name"
              :to="item.href"
              class="mx-2"
              variant="text"
            >{{item.name}}</v-btn>
          </v-sheet>
        </v-col>

        <!-- Get The App -->
        <v-col
          class="align-center"
          cols="12"
          sm="3"
        >
          <v-sheet class="ma-2">
            <v-btn
            size="x-large"
            prepend-icon="mdi-apple"
            class="mx-2"
            variant="outlined"
            >GET THE APP</v-btn>
          </v-sheet>
        </v-col>
      </v-row>
  </v-container>

    <!-- <v-menu style="margin-right:10px;" v-model="languageMenu" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="white" class="text-capitalize" v-bind="attrs" v-on="on" text>
          <v-icon left>mdi-translate</v-icon>
          {{ activeLang }}
          <v-icon small right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="(lang, index) in langs" :key="index" @click="handleMenuItemClick(lang)">
          <v-list-item-title>{{ lang.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->

    <!-- TODO: Enable when ready -->
    <!-- <v-btn rounded color='d-none d-sm-flex secondary primary--text' :to="{ name: 'Login'}"
    style="margin-left:10px;">
      {{login_btn_text}}
    </v-btn> -->
    <!-- <v-btn rounded color='secondary primary--text' :to="{ name: 'Login'}">
      <v-icon>mdi-account</v-icon> {{register_btn_text}}
    </v-btn> -->

    </v-card>

    <!-- Mobile Navigation Drawer -->
    <v-navigation-drawer v-model="drawer" absolute temporary color='primary'>
      <v-list nav dense>
        <v-list-group>
          <v-list-item v-for="(item, index) in mobile_menu_options" :key="item.id"
          :to="item.href">
            <v-list-item-title class="mobileMenu"
            @click="tab = index">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </span>
</template>

<script>
export default {
  name: 'NavigationBar',

  data () {
    return {
      // Flags
      drawer: false,
      tab: null,

      // Buttons
      login_btn_text: 'Login',
      register_btn_text: 'Register',

      menu_options: [
        { name: 'Home', href: '/' },
        { name: '#Challenges', href: '/#challenges' },
        { name: 'Tutorials', href: '/#tutorials' }
      ],

      mobile_menu_options: [
        { name: 'Home', href: '/' },
        { name: '#Challenges', href: '/#challenges' },
        { name: 'Tutorials', href: '/#tutorials' }
      ],
      langs: [
        { title: 'Български', code: 'bg' },
        { title: 'English', code: 'en' }
      ]
    }
  }
}
</script>

<style scoped>
</style>
