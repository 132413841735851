import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

loadFonts()

createApp(App)
  .use(Vue3VideoPlayer, {
  })
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
