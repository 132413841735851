<template>
  <!-- <v-footer class="footer" app bottom fixed padless color="transparent"> -->
    <div>
    <v-card class="flex" color="white" flat tile>
      <v-container color="white" class="primary--text">
        <v-row dense align="center" justify="center">
          <v-col cols="5" dense>
            <router-link to="/">
              <v-img contain max-width='40%'
              src='../assets/logo.svg'
              ></v-img>
            </router-link>

          <v-divider vertical style="margin-right:10px;"></v-divider>

          <!-- <v-menu v-model="languageMenu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="text-capitalize" v-bind="attrs" v-on="on" text>
              <v-icon left>mdi-translate</v-icon>
              {{ activeLang }}
              <v-icon small right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(lang, index) in langs" :key="index" @click="handleMenuItemClick(lang)">
              <v-list-item-title>{{ lang.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->

          </v-col>

          <v-col cols="5" dense align="left" justify="center">
            <v-row>
              <v-col>
                <h3>
                  Learn more
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-for="item in menu_options"
                :key="item.name" cols="6">
                <v-btn
                :to="item.href"
                class="mx-2"
                variant="text"
                >{{ item.name }}</v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="2" dense align="center" justify="center">
            <v-row>
              <v-col>
                <h3>
                  Follow us
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                v-for="item in footer_icons"
                :key="item.name"
                :href="item.href"
                target="_blank"
                color="white"
                class="mx-2"
                size="small"
                icon
              >
                <v-icon size="20px">
                  {{ item.icon }}
                </v-icon>
            </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-container>
    </v-card>
  </div>
  <!-- </v-footer> -->
</template>

<script>
export default {
  name: 'FooterBar',

  data () {
    return {
      // Flags
      drawer: false,
      tab: null,

      // Buttons
      login_btn_text: 'Login',
      register_btn_text: 'Register',

      menu_options: [
        { name: 'Data Processing', href: '/' },
        { name: 'Tutorials', href: '/#challenges' },
        { name: 'Community Guidelines', href: '/#tutorials' },
        { name: 'Terms', href: '/' },
        { name: 'Points & Levels', href: '/#challenges' },
        { name: 'Privacy Policity', href: '/#tutorials' },
        { name: '#Challenges', href: '/#tutorials' }
      ],

      mobile_menu_options: [
        { name: 'Home', href: '/' },
        { name: '#Challenges', href: '/#challenges' },
        { name: 'Tutorials', href: '/#tutorials' }
      ],
      langs: [
        { title: 'Български', code: 'bg' },
        { title: 'English', code: 'en' }
      ],
      footer_icons: [
        { name: 'facebook', icon: 'mdi-facebook', href: '' },
        { name: 'instagram', icon: 'mdi-instagram', href: '' },
        { name: 'twitter', icon: 'mdi-twitter', href: '' },
        { name: 'youtube', icon: 'mdi-youtube', href: '' }
      ]
    }
  }
}
</script>

<style scoped>
</style>
