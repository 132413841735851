<template>
    <div class="shape mb-6" data-negative="false">
        <svg fill="#3f3f3f" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path class="shape-fill" opacity="1"
                d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z">
            </path>
        </svg>

    <!-- <v-container> -->
        <v-row class="align-center" align="center">
            <v-col cols="12" class="mb-4" align="center">
                <h1 class="display-1 text--secondary font-weight-bold mb-3">
                Watch Out Our Feed
                </h1>
            </v-col>
            <v-col>
                <vue3-video-player :src='current_video'></vue3-video-player>
            </v-col>
        </v-row>
        <v-sheet elevation="6">
            <v-tabs
            v-model="tab"
            bg-color="#3f3f3f"
            show-arrows
            height="200px"
            color="deep-purple-accent-2"
            align-tabs="center"
            >
                <v-tab
                    v-for="i in 5"
                    :key="i"
                >
                    <v-card
                    :value="i"
                    elevation="3"
                    rounded="4"
                    height="125px"
                    width="125px"
                    @click="getVideo(i)">
                        <!-- Item {{ i }} -->
                        <v-img
                        :src="thumbnail_feed[i]"
                        cover
                        class="my-3">
                        </v-img>
                    </v-card>
                </v-tab>
            </v-tabs>
        </v-sheet>
    <!-- </v-container> -->
</div>
</template>

<script>
export default {
  name: 'LandingFeed',

  data: () => ({
    tab: null,
    current_video: 'https://x4m.eu/reup/dev/videos/_8jkyvSdo2tmbxPY.mp4',
    video_feed: ['https://x4m.eu/reup/dev/videos/_8jkyvSdo2tmbxPY.mp4', 'https://x4m.eu/reup/dev/videos/_8jkyvSdo2tmbxPY.mp4', 'https://x4m.eu/reup/dev/videos/_8jkyvS-PdUmbxPY.mp4',
      'https://x4m.eu/reup/dev/videos/_m2aMBSdp9CvRMwG.mp4', 'https://x4m.eu/reup/dev/videos/_m2aMBSrEqfvRMwG.mp4', 'https://x4m.eu/reup/dev/videos/_m2aMBSx6NtvRMwG.mp4'],
    thumbnail_feed: ['https://x4m.eu/reup/dev/thumbnails/_8jayvSDBLim3xPY.jpg', 'https://x4m.eu/reup/dev/thumbnails/_8jayvSDBLim3xPY.jpg', 'https://x4m.eu/reup/dev/thumbnails/_8jayvSGD2Tm3xPY.jpg',
      'https://x4m.eu/reup/dev/thumbnails/_8jkyvSdo2tmbxPY.jpg', 'https://x4m.eu/reup/dev/thumbnails/_8jkyvS-PdUmbxPY.jpg', 'https://x4m.eu/reup/dev/thumbnails/_m2aMBSdp9CvRMwG.jpg']
  }),

  methods: {
    getVideo (index) {
      this.current_video = this.video_feed[index]
    }
  }
}
</script>
